.mbsc-ios.mbsc-input .mbsc-label {
    color: #191F3B!important;
}

.mbsc-ios.mbsc-label, .mbsc-ios.mbsc-textfield {
    color: #191F3B!important;
}

.mbsc-popup-header {
    color: #0B7DBB!important;
}

.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    border-color: #0B7DBB!important;
    background: #0B7DBB!important;
}

.mbsc-ios .mbsc-fr-btn {
    color: #0B7DBB!important;
}

.mbsc-ios .mbsc-ms .mbsc-ms-item-sel {
    color: #0FBEDB!important;
}
