.cs-data-range-activity-nav {
    background: #0FBEDB!important;
}

.cs-data-range-activity-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-range-activity-col {
    padding: 0;
}
