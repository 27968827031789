.cs-header-account-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-header-account-popup-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-header-account-helper {
    margin-right: 1em;
    margin-left: 1em;
}
