.cs-data-metric-hr-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-metric-hr-col {
    padding: 0;
}

.cs-data-metric-hr-nav {
    background: #0FBEDB!important;
}
