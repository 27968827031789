.cs-profile-preferences-main {
    background: white;
    padding-bottom: 1.5em;
    margin-right: 0;
    margin-left: 0;
}

.cs-profile-preferences-helper {
    margin-right: 2em;
    margin-left: 1em;
    margin-top: 1.5em;
}
