.cs-data-calendar-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-calendar-input .mbsc-textfield {
    text-align: center;
    color: #0B7DBB!important;
    font-size: 1.5em!important;
    margin: 0!important;
}

.cs-data-calendar-input .mbsc-textfield-wrapper-box {
    margin: 0!important;
}

.cs-data-calendar-select .mbsc-select-input {
    text-align: center;
    color: #0B7DBB!important;
    font-size: 1.5em!important;
}

.cs-data-calendar-empty {
    color: #0B7DBB!important;
    font-size: 1.5em!important;
    margin: 0!important;
}

.cs-data-calendar-button {
    cursor: default;
}
