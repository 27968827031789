.cs-help-grid {
    margin-top: 5em;
    margin-bottom: 5em;
}

.cs-help-grid-small {
    margin-top: 4em;
    margin-bottom: 4em;
    padding-right: 0;
    padding-left: 0;
}

.cs-help-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-help-col {
    padding: 0;
}

.cs-help-button {
    background: #0B7DBB!important;
    color: white!important;
}

.cs-help-button-icon {
    color: white;
    font-size: 1.5em;
    margin-right: .5em;
}
