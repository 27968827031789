.cs-data-grid {
    margin-top: 5em;
    margin-bottom: 5em;
}

.cs-data-grid-small {
    margin-top: 4em;
    margin-bottom: 4em;
    padding-right: 0;
    padding-left: 0;
}

.cs-data-block-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}
