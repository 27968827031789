.cs-login-reset-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-login-reset-cancel {
    color: white!important;
    background: #E97178!important;
    font-weight: bold;
}

.cs-login-reset-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}
