.cs-home-header-toolbar {
  position: fixed;
  background: white;
  z-index: 9999;
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
}

.cs-home-header-col-xs {
  padding: 0!important;
}

.cs-home-header-button {
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-left: 0!important;
  padding-right: 2em!important;
}

.cs-home-header-logo-water {
  margin-left: 2em;
  background-image: url("../images/logo_water.png")!important;
  width: 2.5em;
  height: 2.5em;
  margin-top: .4em;
  margin-bottom: .4em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-home-header-display {
  color: #0B7DBB!important;
  cursor: default;
}

.cs-home-header-display:hover {
  opacity: initial!important;
}

.cs-home-header-menu-text {
  margin-top: .25em!important;
  margin-bottom: -.25em!important;
  padding-left: .75em!important;
}

.cs-home-header-navigation-active {
  color: #0B7DBB;
  font-size: 1.5em;
  padding-top: .5em!important;
}

.cs-home-header-button-menu {
  padding-right: 2em!important;
}

.cs-home-header-navigation-logout {
  color: #E97178;
  font-size: 1.5em;
  padding-top: .5em!important;
}

.cs-home-header-navigation-disabled {
  color: grey;
  font-size: 1.5em;
  padding-top: .5em!important;
}

.cs-home-header-button-icon-pipe {
  padding-top: .5em!important;
  height: 1.5em!important;
}
