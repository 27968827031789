.cs-profile-information-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-profile-information-helper {
    margin-right: 2em;
    margin-left: 1em;
    margin-top: 1.5em;
}
