.cs-home-footer-toolbar {
    position: fixed;
    background: white;
    z-index: 9999;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
    bottom: 0;
    width: 100%;
}

.cs-home-footer-main {
    padding-top: .25em;
    padding-bottom: .25em;
}

.cs-home-footer-nav .mbsc-scv-item {
    padding: 0!important;
}

.cs-home-footer-icon-selected {
    font-size: 2em;
}

.cs-home-footer-button {
    margin: 0!important;
    border-radius: 0!important;
}

.cs-home-footer-icon {
    font-size: 2em;
    color: #12B1B9;
}

