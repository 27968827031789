.cs-data-challenge-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-challenge-col {
    padding: 0;
}

.cs-data-challenge-header {
    padding-bottom: 1em;
}

.cs-data-challenge-leave-icon {
    color: #0B7DBB;
    font-size: 1.5em;
    padding-top: .5em!important;
}

.cs-data-challenge-leave-text {
    margin-top: .25em!important;
    margin-bottom: -.25em!important;
    padding-left: .75em!important;
}

.cs-data-challenge-leave-button {
    margin-right: 1em!important;
}

.cs-data-challenge-highfives {
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-data-challenge-bottom-main {
    padding-bottom: 1em;
}

.cs-data-challenge-leaderboard {
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-data-challenge-leaderboard p {
    margin-bottom: 0!important;
}

.cs-data-challenge-highfives-total h4 {
    margin-bottom: 0!important;
}

.cs-data-challenge-highfives p {
    margin-bottom: 0!important;
}

.cs-data-challenge-leaderboard-name {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 15px;
}

.cs-data-challenge-leaderboard-block {
    padding: 1em;
}

.cs-data-challenge-avatar-highfive {
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: .5em;
    margin-right: -0.5em;
}

.cs-data-challenge-avatar-leaderboard {
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: .5em;
}

.cs-data-challenge-leaderboard-title {
    margin-left: 1em;
    padding-top: .25em;
}

.cs-data-challenge-leaderboard-main {
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 1em;
}

.cs-data-challenge-leaderboard-text {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.cs-data-challenge-leaderboard-stats {
    margin-top: 0;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 0;
}

.cs-data-challenge-cursor {
    cursor: default;
}

.cs-data-challenge-leaderboard-hand {
    color: #0198CE;
    height: 1.75em;
}

.cs-data-challenge-text {
    padding-top: 0.5em;
    margin-right: 1em;
    margin-left: .5em;
}
