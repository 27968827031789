.cs-profile-grid {
    margin-top: 5em;
    margin-bottom: 5em;
}

.cs-profile-grid-small {
    margin-top: 4em;
    margin-bottom: 4em;
    padding-right: 0;
    padding-left: 0;
}

.cs-profile-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-profile-gravatar {
    padding-top: 1em;
    padding-bottom: 0;
}

.cs-profile-col {
    padding: 0;
}

.cs-profile-nav {
    background: #0FBEDB!important;
}

.cs-profile-button {
    color: #0B7DBB!important;
    margin-top: -1em!important;
    margin-bottom: 0!important;
}

.cs-profile-avatar {
    width: 5em;
    height: 5em;
    border-radius: 2.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
}
