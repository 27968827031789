.cs-header-toolbar {
  position: fixed;
  background: white;
  z-index: 9999;
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
}

.cs-header-col-xs {
  padding: 0!important;
}

.cs-header-logo-black {
  margin-left: 2em;
  background-image: url("../images/logo_header.png")!important;
  width: 3em;
  height: 2.5em;
  margin-top: .3em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-header-logo-water {
  margin-left: 2em;
  background-image: url("../images/logo_water.png")!important;
  width: 3em;
  height: 2.5em;
  margin-top: .3em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-header-display {
  color: #0B7DBB!important;
}

.cs-dashboard-header-button-menu {
  padding-right: 2em!important;
}

.cs-header-navigation-active {
  padding-top: .5em!important;
  height: 1.5em!important;
  color: #0B7DBB;
}
