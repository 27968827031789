.cs-data-activity-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-activity-cursor {
    cursor: default;
}

.cs-data-activity-cursor:hover {
    opacity: initial!important;
}

.cs-data-activity-steps {
    color: #0A98CD;
    height: 1.5em;
    padding-right: 1em !important;
}

.cs-data-activity-disabled {
    color: grey;
    height: 1.5em;
    padding-right: 1em !important;
}

.cs-data-activity-text h4 {
    padding-bottom: 0 !important;
}

.cs-data-activity-text-disabled h4 {
    padding-bottom: 0 !important;
    color: grey;
}

.cs-data-activity-distance {
    color: #278C78;
    height: 1.5em;
    padding-right: 1em !important;
}

.cs-data-activity-calories {
    color: #E95C54;
    height: 1.5em;
    padding-right: 1em !important;
}
