.cs-data-challenges-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-challenges-button {
    color: #0B7DBB!important;
}

.cs-data-challenges-col {
    padding: 0;
}

.cs-data-challenges-nav {
    background: #0FBEDB!important;
}
