.cs-device-grid {
    margin-top: 5em;
    margin-bottom: 5em;
}

.cs-device-grid-small {
    margin-top: 4em;
    margin-bottom: 4em;
    padding-right: 0;
    padding-left: 0;
}

.cs-device-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-device-main-multiple {
    background: white;
    margin-right: 0;
    margin-left: 0;
    margin-top: 1em;
}

.cs-device-device-name {
    margin-left: 1em;
}

.cs-device-device-name h3 {
    margin-bottom: 0!important;
}

.cs-device-device-battery {
    margin-left: 0;
    padding-left: 1em;
}

.cs-device-device-details {
    margin-left: 0;
    margin-top: 1em;
    margin-bottom: 1em;
}

.cs-device-device-battery-icon {
    height: 1.25em!important;
}

.cs-device-avatar {
    width: 2em;
    height: 2.75em;
    border-radius: 1.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1.25em;
    margin-left: 1em;
}

.cs-device-detail-battery {
    margin-left: .5em;
    margin-right: .5em;
}

.cs-device-footer {
    margin-top: 1em;
    padding-right: .5em;
    padding-left: 1em;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-device-button-disabled {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-device-button-icon {
    color: #0B7DBB;
    height: 1.5em!important;
    margin-right: .75em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
}
