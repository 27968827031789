.cs-data-sleep-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-data-sleep-main h4 {
    margin-top: 0!important;
}

.cs-data-sleep-start {
    padding-top: 1em;
}

.cs-data-sleep-cursor {
    padding-top: 1em!important;
    padding-bottom: 3em!important;
    cursor: default;
}

.cs-data-sleep-one {
    color: rgba(0, 0, 0);
}

.cs-data-sleep-two {
    color: rgba(177, 181, 185);
}

.cs-data-sleep-three {
    color: rgba(43, 107, 234);
}

.cs-data-sleep-four {
    color: rgba(22, 185, 144);
}

.cs-data-sleep-five {
    color: rgba(250, 169, 25);
}
