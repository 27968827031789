.cs-register-main {
  padding-top: 6em;
}

.cs-register-card {
  background: white;
}

.cs-register-nav {
  background: #0FBEDB!important;
}

.cs-register-text-top {
  margin-top: 1em;
}

.cs-register-inputs-left {
  padding-right: 0!important;
}

.cs-register-mobile-row {
  margin-bottom: -1.5em!important;
  margin-top: -1em!important;
}

.cs-register-inputs-right {
  padding-left: 0!important;
}

.cs-register-inputs-right-input {
  margin-left: 0!important;
}

.cs-register-title {
  color: #0B7DBB;
  text-align: center;
  padding-bottom: 0!important;
}

.cs-register-login {
  color: #0B7DBB!important;
}

.cs-register-form-privacy {
  margin-right: 1em;
  margin-left: 1em;
  max-height: 200px;
  overflow-y: auto;
  background-color: #F1F1F1FF;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cs-register-button {
  background: #0B7DBB!important;
  color: white!important;
}

.cs-register-login-button {
  color: #0B7DBB!important;
  margin-bottom: 2em!important;
  margin-top: -1em!important;
}
