.cs-profile-account-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-profile-account-helper {
    margin-right: 2em;
    margin-left: 1em;
    margin-top: 1.5em;
}

.cs-profile-account-mobile-row {
    margin-bottom: -1.5em!important;
    margin-top: -1em!important;
}

.cs-profile-account-inputs-left {
    padding-right: 0!important;
}

.cs-profile-account-inputs-right {
    padding-left: 0!important;
}

.cs-profile-account-inputs-right-input {
    margin-left: 0!important;
}
