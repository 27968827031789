.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #333;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
